.lineDiv{
    display: flex;
    align-items: center;
}
.line{
    width: 10px;
    height: 34px;
    background: #06B2B2;
    border-radius: 5px;
    margin: 0px 13px 0px 0px;
    display: inline-block;
}
.lineDiv span{
    font-size: 33px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.smallLineDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F7F7F7;
}
.smallLineDiv input{
    text-align: right;
}
.smallLineDiv p{
  line-height: 80px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.van-collapse-item__content{
    padding-top: 0;
    padding-bottom: 0;
}