 /deep/ .van-field__control {
  text-align: right;
}
 /deep/ .van-field__label span {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
 /deep/ .van-field__label {
  flex: 1;
}
